import { useEffect, useState } from "react";
import { Box, Link, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateIcon from "@mui/icons-material/Create";
import { useDataStore } from "../../store/store";
import config from "../../config.json";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import { useNavigate, useSearchParams } from "react-router-dom";

const InputForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState({ variantInput: "" });
  const setVariantInput = useDataStore((state) => state.setVariantInput);
  const setMessage = useDataStore((state) => state.setMessage);
  const navigate = useNavigate();

  // form has been submitted or the url has been changed
  useEffect(() => {
    if (searchParams.get("q")) {
      const input = decompressFromEncodedURIComponent(searchParams.get("q")!);
      setFormValues({
        variantInput: input,
      });
      setVariantInput(input);
      setMessage(undefined);
    }
  }, [searchParams]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (input: string) => {
    const lz = compressToEncodedURIComponent(input);
    // 2000 is the limit for url length in many browsers, but we need some space for the rest of the url
    if (lz.length > 1900) {
      setFormValues({
        variantInput: input,
      });
      setVariantInput(input);
      setMessage(
        "The input is too long to be stored in the url. If you want to share these results, you should share your variant list instead of a direct link."
      );
      navigate("/");
    } else {
      navigate("/?q=" + lz);
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(formValues.variantInput);
  };

  const setExampleData = (data: string) => {
    let input = "";
    if (data == "covid_severe_leads") {
      input =
        "1-46810098-T-C\n1-64947147-G-T\n1-77483438-G-A\n1-155202934-T-C\n2-26616850-G-T\n2-60480453-A-G\n3-45818159-G-A\n4-25312372-A-G\n4-25447603-G-A\n" +
        "4-105897896-G-A\n5-132441275-T-C\n6-29947491-C-T\n6-31182658-A-G\n6-32714422-T-C\n6-41520640-G-A\n7-22854868-T-C\n7-100032719-C-T\n8-60513412-G-A\n" +
        "9-15795835-G-T\n9-21206606-C-G\n10-112972548-C-A\n11-1219991-G-T\n11-34480495-C-T\n12-112922758-T-C\n12-132565387-T-C\n13-112881427-C-T\n16-89196249-G-A\n" +
        "17-45635098-G-A\n17-49863260-C-A\n19-4717660-A-G\n19-10352442-G-C\n19-45869791-ATT-A\n19-50379362-T-C\n21-33237639-A-G\n21-33934844-G-A\n21-41471061-G-A";
    }
    if (data == "covid_infection_leads") {
      input =
        "3-45793925-G-A\n3-101647854-G-A\n3-195770872-T-C\n4-102267552-C-T\n9-133273813-C-T\n14-28990028-A-G\n19-8896954-G-A\n19-48702888-G-C\n23-15602217-T-C";
    }
    if (data == "covid_all") {
      input =
        "3-45818159-G-A\t7.29E-01\tsev\n" +
        "23-15602217-T-C\t-6.50E-01\tsusc\n" +
        "9-21206606-C-G\t4.46E-01\tsev\n" +
        "19-10352442-G-C\t3.82E-01\tsev\n" +
        "6-41520640-G-A\t3.30E-01\tsev\n" +
        "17-49863260-C-A\t2.91E-01\tsev\n" +
        "19-4717660-A-G\t2.40E-01\tsev\n" +
        "3-45793925-G-A\t2.03E-01\tsusc\n" +
        "1-155202934-T-C\t-1.95E-01\tsev\n" +
        "21-33237639-A-G\t1.88E-01\tsev\n" +
        "21-33934844-G-A\t1.79E-01\tsev\n" +
        "6-32714422-T-C\t-1.66E-01\tsev\n" +
        "11-1219991-G-T\t-1.63E-01\tsev\n" +
        "16-89196249-G-A\t1.50E-01\tsev\n" +
        "13-112881427-C-T\t1.38E-01\tsev\n" +
        "6-31182658-A-G\t-1.18E-01\tsev\n" +
        "2-60480453-A-G\t-1.16E-01\tsev\n" +
        "4-102267552-C-T\t1.14E-01\tsusc\n" +
        "4-25312372-A-G\t1.13E-01\tsev\n" +
        "17-45635098-G-A\t-1.12E-01\tsev\n" +
        "9-15795835-G-T\t1.11E-01\tsev\n" +
        "11-34480495-C-T\t-1.09E-01\tsev\n" +
        "4-105897896-G-A\t-1.02E-01\tsev\n" +
        "10-79977061-G-A\t9.59E-02\tnc\n" +
        "14-28990028-A-G\t-9.45E-02\tsusc\n" +
        "12-132565387-T-C\t-9.22E-02\tsev\n" +
        "9-133273813-C-T\t-9.16E-02\tsusc\n" +
        "5-132441275-T-C\t9.06E-02\tsev\n" +
        "12-112922758-T-C\t8.96E-02\tsev\n" +
        "19-48702888-G-C\t-8.74E-02\tsusc\n" +
        "1-64947147-G-T\t-8.71E-02\tsev\n" +
        "19-50379362-T-C\t8.65E-02\tsev\n" +
        "21-41471061-G-A\t-8.59E-02\tsev\n" +
        "6-29947491-C-T\t-8.49E-02\tsev\n" +
        "7-75622276-CA-C\t8.39E-02\tnc\n" +
        "4-25447603-G-A\t-8.36E-02\tsev\n" +
        "7-100032719-C-T\t8.13E-02\tsev\n" +
        "8-60513412-G-A\t7.29E-02\tsev\n" +
        "9-33425186-GTAAC-G\t7.26E-02\tnc\n" +
        "7-22854868-T-C\t-7.12E-02\tsev\n" +
        "1-77483438-G-A\t7.06E-02\tsev\n" +
        "19-45869791-ATT-A\t7.04E-02\tsev\n" +
        "3-101647854-G-A\t-6.55E-02\tsusc\n" +
        "6-33081434-C-T\t5.61E-02\tnc\n" +
        "10-112972548-C-A\t-5.37E-02\tsev\n" +
        "2-26616850-G-T\t4.76E-02\tsev\n" +
        "1-46810098-T-C\t-4.69E-02\tsev\n" +
        "17-39990289-C-G\t4.08E-02\tnc\n" +
        "19-8896954-G-A\t2.90E-02\tsusc\n" +
        "1-155162930-G-A\t-2.89E-02\tnc\n" +
        "3-195770872-T-C\t2.65E-0\tsusc\n";
    }
    if (data == "FinnGen_priority") {
      input =
        "chr5_169588475_G_A\n" +
        "chr5_1272247_G_A\n" +
        "chr1_155419060_A_T\n" +
        "chr16_27344903_G_A\n" +
        "chr4_73167847_G_C\n" +
        "chr1_67169528_G_A\n" +
        "chr4_109740713_T_A\n" +
        "chr6_456585_C_T\n" +
        "chr21_25892808_T_C\n" +
        "chr5_1279370_T_C\n" +
        "chr5_134088944_G_C\n" +
        "chr7_38007020_C_T\n" +
        "chr18_30017911_A_G\n" +
        "chr22_28695868_AG_A\n" +
        "chr16_1947063_G_A\n" +
        "chr19_35851608_CAG_C\n" +
        "chr12_54963054_G_A\n" +
        "chr12_6773332_C_A\n" +
        "chr1_36917732_G_T\n" +
        "chr18_63814283_T_C\n" +
        "chr22_28725099_A_G\n" +
        "chr2_97819285_C_T\n" +
        "chr16_276685_G_A\n" +
        "chr15_48134287_A_G\n" +
        "chr16_1996630_C_T\n" +
        "chr14_99706134_C_T\n" +
        "chr1_62819215_C_CT\n" +
        "chr1_11193760_C_T\n" +
        "chr20_50594588_CTG_C\n" +
        "chr17_1880615_C_T\n" +
        "chr11_22274605_C_T\n" +
        "chr7_28519039_C_T\n" +
        "chr21_44050971_C_G\n" +
        "chr6_52058349_G_A\n" +
        "chr12_49272869_C_T\n" +
        "chr8_132945688_C_T\n" +
        "chr7_5397122_C_T\n" +
        "chr8_144103732_C_T\n" +
        "chr15_64061146_C_T\n" +
        "chr3_38613787_G_A\n" +
        "chr3_150928107_A_C\n" +
        "chr14_23373128_C_T\n" +
        "chr4_25669653_TG_T\n" +
        "chr2_178492087_A_G\n" +
        "chr6_151351050_A_T\n" +
        "chr21_44294411_C_T\n" +
        "chr15_88901702_C_CTGT\n" +
        "chr12_108619376_C_G\n" +
        "chr19_50497261_C_T\n" +
        "chr15_57632516_A_C\n" +
        "chr15_88899813_T_G\n" +
        "chr17_58951922_T_G\n" +
        "chr12_55726956_G_C\n" +
        "chr16_23634953_CA_C\n" +
        "chr1_171793388_A_G\n" +
        "chr2_178579702_G_A\n" +
        "chr19_3540541_G_C\n" +
        "chr1_55039974_G_T\n" +
        "chr19_8364439_G_A\n" +
        "chr13_113140972_G_A\n" +
        "chr4_87313916_G_C\n" +
        "chr2_203202685_A_G\n" +
        "chrX_67711476_G_A\n" +
        "chr21_31667290_A_C\n" +
        "chr14_99668272_G_A\n" +
        "chr6_64277615_C_G\n" +
        "chr2_181603943_G_C\n" +
        "chr9_635964_C_G\n" +
        "chr9_133314565_C_T\n" +
        "chr17_58375686_G_A\n" +
        "chr14_94378610_C_T\n" +
        "chr4_147429490_T_G\n" +
        "chr17_42910962_G_T\n" +
        "chr16_15737546_C_T\n" +
        "chr2_43421765_G_A\n" +
        "chr11_2778009_G_A\n" +
        "chr11_47333566_G_A\n" +
        "chr11_46728030_C_G\n" +
        "chr6_25969403_C_T\n" +
        "chr20_64286122_C_A\n" +
        "chr19_19347579_A_G\n" +
        "chr8_17866505_T_A\n" +
        "chr2_27259441_C_T\n" +
        "chr7_87452957_T_C\n" +
        "chr2_241191700_C_T\n" +
        "chr1_247424041_G_A\n" +
        "chr17_40558934_T_C\n" +
        "chr16_57901371_T_A\n" +
        "chr9_97696990_G_C\n" +
        "chr12_56173736_G_A\n" +
        "chr1_247424123_C_T\n" +
        "chr1_196994128_C_CA\n" +
        "chr22_43082544_C_T\n" +
        "chr14_60509871_A_C\n" +
        "chr6_41160090_C_T\n" +
        "chr19_50276163_G_A\n" +
        "chr17_58358769_C_T\n";
    }
    if (data == "1krandom") {
      input =
        "1:1734903:A:G\n1:8003781:T:C\n1:10852494:C:T\n1:12809485:GTGT:G\n1:4282583:A:G\n1:3812899:GTAAC:G\n1:1267057:A:T\n1:3292940:G:A\n1:12809590:T:G\n1:11074613:A:G\n" +
        "1:5762539:G:A\n1:7470191:C:G\n1:12714470:A:G\n1:12805442:C:A\n1:2597753:A:G\n1:8136005:G:A\n1:5330518:C:T\n1:11294303:TTTA:T\n1:4923066:C:T\n1:3341978:T:A\n" +
        "1:11160539:C:A\n1:1560477:T:TCGTA\n1:7637453:G:A\n1:12698288:A:T\n1:11710573:G:A\n1:7698822:A:C\n1:966061:C:T\n1:4138641:T:C\n1:3304871:G:T\n1:629711:G:A\n" +
        "1:3016055:A:G\n1:2585382:T:TG\n1:4714013:C:G\n1:1489936:C:G\n1:9383507:A:G\n1:3402606:A:T\n1:5240100:T:C\n1:4555756:C:T\n1:7811789:C:T\n1:7686842:G:A\n" +
        "1:1559333:C:T\n1:1041497:C:G\n1:7035299:C:T\n1:11467009:G:A\n1:11617408:A:G\n1:9151306:T:A\n1:8345946:C:T\n1:6463464:T:C\n1:1262161:A:G\n1:11713561:G:A\n" +
        "1:2202313:C:T\n1:6884744:T:C\n1:1603989:T:C\n1:3416044:G:A\n1:7837168:G:A\n1:3237730:A:G\n1:5262995:T:C\n1:2308635:A:G\n1:4721036:C:G\n1:10184018:C:T\n" +
        "1:821740:A:G\n1:7900691:G:A\n1:7697932:G:C\n1:8567851:C:T\n1:12325725:A:G\n1:10408889:G:A\n1:5113603:T:C\n1:1586679:C:T\n1:10439007:T:C\n1:1017366:C:T\n" +
        "1:3780326:A:C\n1:9867196:A:G\n1:11978327:AC:A\n1:988598:A:AG\n1:11617166:G:A\n1:7316611:C:T\n1:11233520:G:A\n1:7995365:C:T\n1:11279135:A:G\n1:3137542:T:C\n" +
        "1:5742155:A:G\n1:6631399:G:A\n1:6335404:G:A\n1:11798313:A:C\n1:11244358:C:G\n1:5535567:G:A\n1:1393769:C:T\n1:730231:C:G\n1:9145196:G:A\n1:9940342:T:A\n" +
        "1:10044777:TTTACTATGTTGGC:T\n1:4784605:G:A\n1:4895327:G:GACAC\n1:2951124:C:A\n1:8261918:A:G\n1:9271066:C:T\n1:1188963:C:T\n1:7720185:CT:C\n1:3286446:A:C\n1:9008410:G:A\n" +
        "1:1599198:G:C\n1:1424703:CAA:C\n1:5521629:T:C\n1:8582386:T:TTC\n1:12428251:C:T\n1:5222575:G:A\n1:11064429:C:T\n1:10875538:G:A\n1:2266593:G:A\n1:10881073:C:T\n" +
        "1:138396:G:A\n1:3574070:C:A\n1:2783572:G:C\n1:1708001:A:T\n1:6695767:C:T\n1:2355508:A:G\n1:3000463:C:A\n1:6630854:G:C\n1:10834924:T:G\n1:5888394:G:A\n" +
        "1:999637:G:A\n1:10881104:C:T\n1:7711643:T:G\n1:1586696:T:C\n1:6408123:T:C\n1:10950796:G:A\n1:4491666:G:C\n1:1776602:G:A\n1:6566555:C:A\n1:9235947:T:G\n" +
        "1:3043496:G:A\n1:9240456:T:G\n1:5114649:T:C\n1:2043487:G:A\n1:1531601:A:G\n1:2162377:C:T\n1:2334355:G:A\n1:2811239:C:T\n1:1351675:G:A\n1:9190775:G:A\n" +
        "1:5923975:T:C\n1:11837535:A:G\n1:3232581:G:A\n1:7911018:G:A\n1:10002030:G:A\n1:3698518:C:G\n1:10631671:C:T\n1:5081013:A:T\n1:2845054:C:T\n1:991051:A:T\n" +
        "1:12369277:A:T\n1:4189992:T:G\n1:2621713:G:A\n1:3848822:G:A\n1:4448984:G:A\n1:11962436:C:T\n1:6092045:CCT:C\n1:3623899:C:G\n1:4825397:G:A\n1:7749999:A:AT\n" +
        "1:941767:G:A\n1:5702442:A:G\n1:5281839:A:G\n1:11767335:G:T\n1:7021006:C:T\n1:4000680:C:G\n1:11596767:C:T\n1:10770189:A:G\n1:11781865:G:A\n1:1477677:T:TTTTTATTTTATTTTA\n" +
        "1:2189809:G:C\n1:8228316:G:T\n1:9877078:C:T\n1:1525704:A:G\n1:1193153:G:A\n1:1923422:G:A\n1:3268889:G:A\n1:1378109:C:T\n1:6031838:G:A\n1:9264235:GCTTTGGCCAGTTCCACCTGGCA:G\n" +
        "1:10355107:G:T\n1:9434427:A:G\n1:8971990:T:C\n1:7001370:C:T\n1:3133201:A:T\n1:1366828:A:G\n1:3946747:C:T\n1:2186869:G:A\n1:2335270:C:T\n1:7667177:C:T\n" +
        "1:4793008:A:G\n1:182204:G:A\n1:9259878:T:A\n1:12652278:G:A\n1:3575632:A:ATG\n1:1585900:C:T\n1:5193485:A:T\n1:4317186:GTGT:G\n1:7789596:A:G\n1:4216843:T:C\n" +
        "1:8658779:A:G\n1:1475244:T:TC\n1:1182019:C:T\n1:5476850:T:G\n1:9714500:GT:G\n1:9861754:G:A\n1:3675919:C:T\n1:9542213:C:T\n1:11776273:AAAAAAC:A\n1:11580663:C:A\n" +
        "1:12650863:C:A\n1:3133148:C:T\n1:11664458:G:C\n1:4807849:C:T\n1:2043528:G:A\n1:4508782:T:C\n1:2874893:C:T\n1:5728064:C:T\n1:4770769:G:C\n1:11451698:G:A\n" +
        "1:6351867:A:G\n1:11064715:C:T\n1:3902760:A:G\n1:10281279:T:A\n1:5967276:C:T\n1:10832078:C:CCT\n1:12011035:C:T\n1:3142864:G:A\n1:1299165:C:T\n1:12078137:C:T\n" +
        "1:11517069:C:T\n1:986724:C:T\n1:6540947:T:A\n1:11568338:C:T\n1:10836553:T:C\n1:8850188:C:T\n1:12287221:T:C\n1:11548090:T:TTTTG\n1:4840561:T:C\n1:12040885:A:T\n" +
        "1:3413707:A:G\n1:12400953:TGCGC:T\n1:7907237:C:T\n1:1246889:G:A\n1:12243952:A:T\n1:11225127:G:A\n1:5388752:G:A\n1:12104033:C:T\n1:11262571:G:T\n1:12043572:G:A\n" +
        "1:9986402:A:G\n1:10940664:T:A\n1:7420186:G:A\n1:9501939:T:C\n1:1239574:T:C\n1:9784917:A:G\n1:6541276:C:T\n1:2278113:C:A\n1:12717358:G:A\n1:11845917:A:G\n" +
        "1:3727998:G:C\n1:11998109:G:C\n1:12646766:G:A\n1:9111179:G:T\n1:12458095:T:G\n1:8473941:G:A\n1:2495769:C:T\n1:4904977:A:AT\n1:1660716:C:T\n1:2646347:G:A\n" +
        "1:4481384:G:A\n1:12112567:T:A\n1:12173006:G:C\n1:4707897:G:A\n1:12806050:T:TCTGA\n1:3349808:G:A\n1:8307617:T:C\n1:3050123:C:T\n1:1120968:G:A\n1:6883660:A:G\n" +
        "1:2226499:CG:C\n1:1325967:C:T\n1:6718645:A:T\n1:7093804:T:C\n1:1290204:G:A\n1:10565981:A:G\n1:11487028:A:G\n1:11434051:G:A\n1:1173724:G:T\n1:9473108:A:G\n" +
        "1:1170681:G:A\n1:9671128:C:T\n1:4119048:C:T\n1:6344359:C:T\n1:10866849:G:T\n1:4461814:A:G\n1:4728712:C:T\n1:4735686:C:T\n1:9213871:G:A\n1:4154548:G:C\n" +
        "1:5501582:CCCT:C\n1:8884327:G:A\n1:5265636:T:C\n1:12231765:G:A\n1:12635975:G:A\n1:2458126:C:T\n1:7903447:C:T\n1:3693359:A:G\n1:6950521:C:T\n1:4452930:GA:G\n" +
        "1:7658295:C:T\n1:8124172:G:A\n1:9739942:G:A\n1:947423:A:G\n1:9006908:C:A\n1:6621853:G:A\n1:7659728:A:AG\n1:12126335:G:A\n1:10743340:G:A\n1:12023734:A:G\n" +
        "1:1789499:C:T\n1:1742672:C:T\n1:2767982:G:C\n1:9848389:G:A\n1:5290297:A:G\n1:1225670:G:A\n1:6004846:C:T\n1:4622272:T:C\n1:4664814:G:A\n1:9877452:G:T\n" +
        "1:11949720:C:G\n1:852630:A:G\n1:1311268:C:T\n1:3953894:G:A\n1:9359123:G:T\n1:3738886:AG:A\n1:10376864:CACAT:C\n1:11217567:AT:A\n1:4032861:T:C\n1:12788512:G:A\n" +
        "1:5083666:C:T\n1:10864653:G:A\n1:10477348:G:A\n1:4236114:C:T\n1:1235414:C:T\n1:4432920:C:A\n1:4443416:T:TCCTCA\n1:10498487:C:T\n1:4645946:C:G\n1:5513941:G:A\n" +
        "1:2356958:C:A\n1:10462043:A:G\n1:1505017:CAA:C\n1:1156322:A:G\n1:4371255:A:G\n1:12295078:CG:C\n1:5406906:A:G\n1:7138769:C:T\n1:11943477:A:G\n1:11760701:T:C\n" +
        "1:6980423:G:A\n1:5050545:G:A\n1:6518390:G:A\n1:5039777:C:G\n1:821080:A:G\n1:7064734:A:G\n1:10359399:G:C\n1:1155480:G:A\n1:2851210:C:T\n1:12381023:T:A\n" +
        "1:12651241:C:T\n1:10229748:C:G\n1:11315328:G:A\n1:12736846:C:T\n1:7462082:C:G\n1:4177562:G:C\n1:12439093:C:G\n1:5654632:G:A\n1:3563277:C:T\n1:10917588:C:T\n" +
        "1:10489533:C:T\n1:3658754:GCATATTTTGGGGTGACGTATTCTGGTCTCCTACAGT:G\n1:9008603:G:GT\n1:7393018:G:T\n1:9221801:TA:T\n1:1293971:C:T\n1:1438643:C:A\n1:7463720:G:A\n1:5460203:T:C\n1:9071131:C:G\n" +
        "1:7547829:GC:G\n1:5316537:T:A\n1:12282091:T:C\n1:9324366:C:T\n1:2033605:C:T\n1:3206407:G:A\n1:1631710:T:C\n1:3510989:C:T\n1:11017719:ATTAAAT:A\n1:10969421:G:T\n" +
        "1:1436415:G:A\n1:7955376:G:T\n1:9817494:A:C\n1:7373386:C:T\n1:1767804:C:T\n1:4105133:C:T\n1:6393684:C:A\n1:5888804:C:T\n1:5574224:G:A\n1:5312441:C:A\n" +
        "1:10872572:G:A\n1:5532443:C:T\n1:7347801:G:A\n1:4265664:C:T\n1:2969577:G:A\n1:6021957:G:A\n1:7361145:T:C\n1:3378466:G:A\n1:2198780:C:T\n1:4308381:A:T\n" +
        "1:7993075:A:G\n1:6745061:G:A\n1:4776940:C:G\n1:7523648:A:G\n1:194662:G:A\n1:7104236:G:A\n1:6609150:AAAT:A\n1:12208664:G:A\n1:7303439:A:G\n1:6902859:G:GCAAACA\n" +
        "1:12142880:C:T\n1:10242267:G:A\n1:12481468:T:A\n1:10316233:C:CA\n1:8036792:G:A\n1:8458840:T:C\n1:995849:C:T\n1:10924146:G:A\n1:6317150:C:G\n1:9236242:G:A\n" +
        "1:1130519:C:A\n1:3671391:C:T\n1:8316298:A:AC\n1:1931838:G:A\n1:5708554:TGAGA:T\n1:10318074:A:AT\n1:9674811:T:C\n1:5215288:G:GA\n1:3462343:G:A\n1:5114843:T:C\n" +
        "1:10691544:G:T\n1:2475686:G:A\n1:9639974:G:T\n1:5299891:A:G\n1:4863502:G:A\n1:11959312:C:T\n1:8371547:C:T\n1:7665249:C:T\n1:5863367:A:T\n1:10982813:G:A\n" +
        "1:12802186:G:A\n1:2340625:G:A\n1:3542212:TGGATGGGTGG:T\n1:2968700:G:A\n1:6559660:A:G\n1:2983398:G:C\n1:3274822:TGGTGTCGA:T\n1:6016362:C:G\n1:12476740:T:C\n1:6433732:C:T\n" +
        "1:9001966:C:G\n1:9274655:G:A\n1:8776391:A:G\n1:5331212:T:G\n1:3587296:C:T\n1:3010907:C:T\n1:11832037:T:C\n1:5415601:G:A\n1:3442358:G:C\n1:4555755:G:A\n" +
        "1:3773045:T:C\n1:12650944:A:T\n1:11084548:C:T\n1:8518749:T:C\n1:12022404:C:T\n1:1113609:A:G\n1:6153336:C:T\n1:12538975:A:C\n1:3346696:G:A\n1:5685432:G:A\n" +
        "1:6123217:C:T\n1:4226327:C:T\n1:7661388:C:T\n1:918328:G:A\n1:1730684:C:T\n1:4954162:C:T\n1:11067388:T:C\n1:1484577:T:C\n1:1090562:C:T\n1:7155047:C:G\n" +
        "1:3202953:A:C\n1:10107298:G:A\n1:3761741:C:T\n1:4400420:C:G\n1:12368077:G:A\n1:4372428:T:C\n1:5311680:C:G\n1:944102:G:C\n1:9190364:T:C\n1:939147:A:T\n" +
        "1:1254827:C:T\n1:3706329:G:T\n1:4518789:A:C\n1:12208580:C:T\n1:1091421:T:A\n1:8089395:C:CATAG\n1:7947972:A:C\n1:1648858:C:G\n1:1340399:C:A\n1:8371688:G:C\n" +
        "1:1347793:G:T\n1:6389282:C:CA\n1:6722253:C:T\n1:6207471:C:T\n1:3873768:T:C\n1:12107569:A:AAATATACT\n1:12457070:C:T\n1:8347805:C:T\n1:6751740:C:CTGTG\n1:7537078:ACATT:A\n" +
        "1:982890:C:T\n1:2556887:AC:A\n1:4622854:C:T\n1:11344541:T:C\n1:3287313:G:A\n1:7442771:C:A\n1:9919357:A:AAAACAAACAAAC\n1:9030935:C:T\n1:4389556:T:C\n1:10674083:A:T\n" +
        "1:9090612:C:T\n1:2271644:C:T\n1:12591641:G:A\n1:1784028:AAAC:A\n1:7608945:A:G\n1:2390905:G:A\n1:2337380:G:A\n1:4821207:T:C\n1:3390707:G:A\n1:2826571:C:T\n" +
        "1:4373792:T:C\n1:2469776:C:T\n1:6741844:C:A\n1:135118:G:C\n1:6278441:G:A\n1:7257080:G:GC\n1:7051663:C:CTGCCAGGACAGACTCATCCCTGTT\n1:5712004:TC:T\n1:7333968:C:G\n1:754894:C:T\n" +
        "1:5293738:CT:C\n1:3384389:G:A\n1:6362281:A:G\n1:1254544:C:T\n1:865074:C:G\n1:2640384:A:G\n1:3220978:T:C\n1:8874610:A:T\n1:10038003:G:A\n1:10141992:C:G\n" +
        "1:7106121:A:G\n1:11065098:C:CA\n1:5290200:GAA:G\n1:2905303:G:T\n1:2437459:CA:C\n1:3614675:A:C\n1:11019329:G:T\n1:4228060:C:T\n1:7689747:A:G\n1:5162668:A:G\n" +
        "1:9957537:C:A\n1:7412127:A:G\n1:5837647:A:AT\n1:3162165:A:G\n1:11107305:C:T\n1:9549245:G:C\n1:9701453:C:T\n1:4960276:T:C\n1:6446656:G:A\n1:2865720:C:T\n" +
        "1:9606618:C:T\n1:4416221:C:T\n1:2347631:G:A\n1:4085968:A:C\n1:833844:G:A\n1:5035147:T:C\n1:3255617:C:T\n1:12523295:G:A\n1:4087901:T:C\n1:5974140:G:A\n" +
        "1:11478192:C:T\n1:4211466:C:A\n1:7685672:T:C\n1:1110693:C:A\n1:4037646:A:G\n1:4475411:C:T\n1:11687342:C:T\n1:4704095:A:T\n1:6139617:C:G\n1:4106306:C:T\n" +
        "1:3336344:ATG:A\n1:4316176:G:A\n1:3215845:G:A\n1:4441694:AG:A\n1:10500157:CTG:C\n1:2928929:A:T\n1:5282387:T:C\n1:6005448:G:GGGTGAGGGTGGAGTGGGGTGACGTGGAAGCTGAGCTGAGA\n1:2069657:C:T\n1:1291670:A:C\n" +
        "1:8550528:TTTGTTG:T\n1:10148282:A:G\n1:9778897:A:T\n1:6112159:G:A\n1:4985487:C:T\n1:10547337:C:T\n1:3130444:G:A\n1:11854646:G:A\n1:7778290:C:T\n1:12373025:G:A\n" +
        "1:12242015:C:A\n1:1110311:G:A\n1:3671974:C:A\n1:11407297:G:T\n1:2100752:G:A\n1:6447876:G:C\n1:6780753:G:A\n1:11986032:G:A\n1:3965418:C:G\n1:7893491:CTAAA:C\n" +
        "1:2458263:T:C\n1:3851469:G:A\n1:11005864:C:T\n1:5692421:C:T\n1:7415957:G:A\n1:5912260:C:T\n1:7236749:T:G\n1:9064280:A:ATTT\n1:1565561:A:G\n1:7034424:G:T\n" +
        "1:11408385:T:C\n1:12682897:T:C\n1:12522366:CAG:C\n1:9320584:C:T\n1:3769207:C:T\n1:9362959:A:C\n1:7253892:T:C\n1:9273873:A:G\n1:8301269:C:A\n1:9240958:T:G\n" +
        "1:9466155:C:T\n1:11293059:G:T\n1:10917599:C:T\n1:1569173:C:CA\n1:4764378:G:A\n1:7956785:C:G\n1:3383262:G:A\n1:9732047:G:GA\n1:3092033:A:G\n1:2566202:G:A\n" +
        "1:5742260:C:T\n1:9952964:C:T\n1:8949411:G:C\n1:8562752:A:G\n1:2842697:C:A\n1:8956243:T:G\n1:7198067:C:T\n1:5853833:C:T\n1:5284505:T:C\n1:10313624:C:T\n" +
        "1:8779091:C:G\n1:12593392:G:A\n1:1346126:G:A\n1:7032489:C:T\n1:9694435:G:A\n1:5295185:T:G\n1:10916535:C:T\n1:2110897:G:A\n1:4901737:G:A\n1:4591945:G:C\n" +
        "1:3376407:T:C\n1:2067154:T:G\n1:3127870:G:A\n1:6310418:C:T\n1:6598679:A:G\n1:2605602:T:G\n1:8788666:G:GT\n1:6769505:T:C\n1:10297748:G:C\n1:10121694:A:G\n" +
        "1:1170126:C:T\n1:983323:G:A\n1:7553538:G:A\n1:4435503:C:T\n1:5579990:C:T\n1:6786419:T:G\n1:6725587:T:G\n1:11771558:C:T\n1:1409102:A:G\n1:11841637:G:A\n" +
        "1:9637441:G:A\n1:919598:A:C\n1:12369868:A:G\n1:1341559:C:T\n1:3233451:C:CA\n1:6385821:C:T\n1:10496428:T:C\n1:4861898:C:A\n1:1022658:A:G\n1:4475394:A:T\n" +
        "1:5395507:T:C\n1:2487976:C:G\n1:4811453:G:A\n1:12566100:CAG:C\n1:11149725:C:T\n1:7296031:G:A\n1:10253877:C:T\n1:6671863:C:G\n1:9394721:C:T\n1:7944581:A:T\n" +
        "1:2827100:C:T\n1:11710652:T:C\n1:10338828:C:T\n1:7920069:C:G\n1:4646513:C:G\n1:4591144:G:A\n1:12597009:C:T\n1:3946372:T:C\n1:12627834:T:A\n1:5547242:A:C\n" +
        "1:11866876:C:A\n1:11615900:C:CCAT\n1:9056131:G:C\n1:3105278:G:A\n1:4266800:C:T\n1:9541925:G:A\n1:5389232:A:G\n1:1584221:G:A\n1:6023503:G:A\n1:11821263:G:C\n" +
        "1:1734174:A:G\n1:7256895:A:G\n1:10617896:G:C\n1:5338197:G:A\n1:11760811:CCTTTT:C\n1:1099341:T:C\n1:6468505:G:A\n1:11056886:A:AT\n1:12441029:G:A\n1:6279116:T:C\n" +
        "1:7259811:A:G\n1:11690578:T:C\n1:6156835:A:G\n1:5348063:T:C\n1:10744281:G:A\n1:10230603:T:G\n1:1020239:G:C\n1:4685690:A:G\n1:4008260:C:T\n1:5635918:T:G\n" +
        "1:9091691:C:T\n1:1204210:A:C\n1:8742729:G:A\n1:6847901:C:A\n1:7918342:A:G\n1:4298712:G:A\n1:2782723:G:A\n1:3883916:G:T\n1:8850646:A:T\n1:1034835:G:C\n" +
        "1:7188092:C:A\n1:4723640:G:A\n1:10094578:C:T\n1:8804854:C:T\n1:7701013:AGAGT:A\n1:12603836:T:C\n1:7591092:C:T\n1:10503614:G:C\n1:5965271:T:C\n1:2065711:G:A\n" +
        "1:8854337:T:C\n1:10941367:G:A\n1:6806918:G:A\n1:8047231:A:G\n1:1283472:G:T\n1:7832563:C:T\n1:5215863:G:A\n1:1733585:C:T\n1:1474873:C:T\n1:11444395:G:A\n" +
        "1:3881485:A:G\n1:10343777:ATAAAATAAAG:A\n1:8656247:T:TCGGTCC\n1:11909958:G:A\n1:9807368:G:C\n1:11969185:C:T\n1:7455035:G:A\n1:11615457:G:A\n1:10745687:C:T\n1:4462656:G:A\n" +
        "1:7108625:G:C\n1:1432050:G:A\n1:12810522:T:C\n1:3372225:G:T\n1:8231980:A:G\n1:9494568:C:T\n1:10323602:CAG:C\n1:3423552:A:G\n1:2016333:T:C\n1:4518435:A:G\n" +
        "1:9521525:C:T\n1:10589450:T:C\n1:3526629:C:T\n1:8234673:C:T\n1:10901420:G:T\n1:9423329:G:A\n1:5288419:G:A\n1:3656998:A:G\n1:2068268:A:AC\n1:2067213:C:T\n" +
        "1:6714762:A:G\n1:4784763:G:A\n1:10860415:C:G\n1:2253416:G:C\n1:1987598:G:A\n1:5183383:A:AGTTCACTCATTTTTTCTTCTACAGT\n1:5800759:TG:T\n1:10955645:C:T\n1:5027597:C:A\n1:10932549:C:A\n" +
        "1:7261236:G:A\n1:4605062:T:G\n1:10149019:G:A\n1:11453875:G:A\n1:1080336:G:A\n1:5464522:G:A\n1:1458166:A:T\n1:6714473:G:GTTT\n1:7558057:A:C\n1:4104222:G:A\n" +
        "1:5244117:C:A\n1:10643914:C:A\n1:5568297:C:T\n1:5874117:GA:G\n1:5830334:A:G\n1:1142710:T:C\n1:10148984:A:G\n1:12154033:T:C\n1:11034581:C:T\n1:10909037:G:A\n" +
        "1:8597390:C:T\n1:4614184:G:A\n1:6037671:T:C\n1:12547683:C:T\n1:9387907:C:T\n1:9914406:T:A\n1:8521784:TC:T\n1:4575189:C:T\n1:2690639:A:C\n1:10979510:T:C\n" +
        "1:4790916:TTTTTTG:T\n1:10597528:C:A\n1:2211099:G:A\n1:2935048:C:T\n1:6792748:T:G\n1:9447816:A:C\n1:11538011:C:T\n1:113767:G:T\n1:12718596:A:T\n1:4096118:G:A\n" +
        "1:1149592:G:A\n1:4811758:C:T\n1:7011518:C:T\n1:1290651:A:G\n1:2854086:C:A\n1:7142796:T:C\n1:2298473:C:T\n1:12311750:G:A\n1:5885893:G:C\n1:10022810:C:G\n" +
        "1:12184482:G:T\n1:5847852:G:C\n1:2132044:C:G\n1:3216038:A:G\n1:4703341:C:T\n1:7507558:C:T\n1:10885408:C:A\n1:11188658:A:G\n1:6824247:T:C\n1:3500458:C:T\n" +
        "1:11911209:C:G\n1:5287567:T:C\n1:1352069:T:A\n1:7081932:T:A\n1:10363137:TAAGAA:T\n1:11664035:G:C\n1:9412922:T:C\n1:5827058:C:A\n1:4056544:T:C\n1:931558:G:A\n" +
        "1:11539318:A:C\n1:1450709:C:T\n1:8706191:C:T\n1:9228453:A:G\n1:6419878:A:G\n1:10682135:C:G\n1:6878396:A:G\n1:2522632:AAAC:A\n1:1982333:C:T\n1:1443101:G:A\n" +
        "1:9656067:G:A\n1:3015089:C:T\n1:9131183:C:A\n1:9989294:C:T\n1:2025865:G:T\n1:1039114:G:T\n1:7247732:G:A\n1:7626849:A:G\n1:5498666:T:G\n1:2522431:G:A\n" +
        "1:3269644:G:A\n1:5946064:C:T\n1:6100638:A:G\n1:837575:T:G\n1:1477677:TTTTTA:T\n1:7246483:C:T\n1:8698360:G:C\n1:11092308:G:A\n1:12138240:T:C\n1:3355253:G:T\n" +
        "1:3441520:G:T\n1:12571498:G:A\n1:10595719:C:T\n1:4418822:C:T\n1:3484203:G:A\n1:10093627:A:C\n1:4407748:G:A\n1:3390678:G:T\n1:8297659:C:CTT\n1:2227589:T:C\n" +
        "1:4007452:C:T\n1:1230546:C:T\n1:9426689:C:T\n1:3591664:T:G\n1:7794880:CTGTTA:C\n1:2490290:T:C\n1:1625961:A:G\n1:6284513:T:C\n1:11988007:G:T\n1:1959717:C:T\n" +
        "1:2841185:A:G\n1:8173798:C:G\n1:5045230:G:C\n1:5429692:T:C\n1:1447402:C:CT\n1:5492623:A:AG\n1:5817665:C:T\n1:11057477:T:G\n1:10609387:A:G\n1:9447507:T:C\n" +
        "1:2159333:A:G\n1:10054878:C:T\n1:9251436:CTGT:C\n1:1504666:A:AAGAG\n1:4321193:G:T\n1:9073346:A:C\n1:9521867:T:A\n1:6566949:T:C\n1:12770448:T:C\n1:2250723:C:T\n" +
        "1:4291704:A:G\n1:1689687:A:G\n1:12207359:TTTTGTTTG:T\n1:10303429:T:C\n1:11342692:T:C\n1:5799466:T:C\n1:7083961:C:CT\n1:6114016:G:A\n1:3356324:G:A\n1:1493406:T:C\n" +
        "1:12591658:C:T\n1:6485977:A:AC\n1:12588370:G:A\n1:6689838:A:G\n1:6904617:A:G\n1:4273235:T:A\n1:12204404:G:A\n1:1079720:G:A\n1:12336648:A:C\n1:4554774:C:A\n";
    }
    handleSubmit(input);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "260px" }}>
          <TextField
            sx={{ marginBottom: "10px", width: "260px" }}
            id="filled-multiline-flexible"
            name="variantInput"
            label="Paste GRCh38 variant ids or rsids"
            value={formValues.variantInput}
            multiline
            rows={10}
            variant="outlined"
            onChange={handleInputChange}
          />
          <LoadingButton
            sx={{ marginBottom: "10px", width: "260px" }}
            size="small"
            startIcon={<CreateIcon />}
            loading={false}
            loadingPosition="start"
            variant="contained"
            type="submit">
            <span>annotate</span>
          </LoadingButton>
          <Typography sx={{ marginBottom: "10px" }}>
            Or try <br />
            {config.target === "finngen" ? (
              <>
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setExampleData("FinnGen_priority");
                  }}>
                  FinnGen EA5 priority variants
                </Link>
                <br />
              </>
            ) : null}
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setExampleData("covid_severe_leads");
              }}>
              COVID-19 severity lead variants
            </Link>
            <br />
            {/* <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setExampleData("covid_infection_leads");
              }}>
              COVID-19 susceptibility lead variants
            </Link>
            <br /> */}
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setExampleData("covid_all");
              }}>
              COVID-19 all lead variants
            </Link>
            <br />
          </Typography>
        </Box>
      </form>
    </>
  );
};

export default InputForm;
